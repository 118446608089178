/** Import zmiennych */
@import 'variables';

/** Libraries */
@import 
        "node_modules/bootstrap/scss/bootstrap",
        "node_modules/owl.carousel/src/scss/owl.carousel",
        "../fonts/font-awesome/scss/font-awesome.min",
        "vendor/animate.min",
        "vendor/fonts/fontello",
        "vendor/bs-border-utilities",
        "vendor/modal-dialog",
        "vendor/sweetalert2.min.scss";

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,200;0,400;0,700;1,200;1,400;1,700&display=swap');        

/** Import pozostalych styli */
@import 'imports';        
        
/** Import ogolnego motywu */
@import 'theme';
@import 'theme-sm';
@import 'theme-md';
@import 'theme-lg';
@import 'theme-xl';
@import 'theme-xxl';
